<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style lang="scss">
@import "~styles/_app.scss";
</style>
