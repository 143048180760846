import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import fb, { auth, storage } from '../firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    errors: [],
    users: [],
    tickets: [],
    questions: [],
    points: [],
    loading: false
  },
  mutations: {
    setUser(state, { uid, phoneNumber, photoURL, email, displayName }) {
      state.currentUser = { uid, phoneNumber, photoURL, email, displayName }
    },
    setErrors(state, errors) {
      state.errors = errors
    },
    setUsers(state, users) {
      state.users = users
    },
    removeUser(state) {
      state.currentUser = null
      state.errors = []
      state.users = []
      state.tickets = []
      state.questions = []
      state.points = []
      state.loading = false
    },
    setTickets(state, tickets) {
      state.tickets = tickets
    },
    setQuestions(state, questions) {
      state.questions = questions
    },
    addTickets(state, tickets) {
      state.tickets = state.tickets.concat(tickets)
    },
    replaceTickets(state, tickets) {
      tickets.forEach((item) => {
        if (!state.tickets.some(ticket => ticket.id === item.id)) {
          state.tickets.unshift(item)
        }
      })
    },
    setLoading(state, value) {
      state.loading = value
    },
    setPoints(state, value) {
      state.points = value
    }
  },
  actions: {
    async login({ commit }, { email, password }) {
      commit('setLoading', true)
      commit('setErrors', [])
      try {
        const { user } = await auth.signInWithEmailAndPassword(email, password)
        commit('setUser', user)
        router.replace({ name: 'Tickets' })
      } catch (err) {
        commit('setErrors', [err.message])
      }
      commit('setLoading', true)
    },
    async logout({ commit }) {
      commit('setLoading', true)
      commit('setErrors', [])
      try {
        commit('removeUser')
        router.replace({ name: 'Login' })
      } catch (err) {
        commit('setErrors', [err.message])
      }
      commit('setLoading', true)
    },
    async getUsers({ commit }) {
      commit('setLoading', true)
      commit('setUsers', [])
      try {
        const { docs } = await fb.collection('usuarios').get()
        const items = await Promise.all(docs.map(async item => {
          const data = await item.data()
          return {
            ...data,
            id: item.id
          }
        }))
        commit('setUsers', items)
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async getQuestions({ commit }) {
      commit('setLoading', true)
      commit('setQuestions', [])
      try {
        const { docs } = await fb.collection('preguntas').get()
        const items = await Promise.all(docs.map(async item => {
          const data = await item.data()
          return {
            ...data,
            id: item.id
          }
        }))
        commit('setQuestions', items)
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async getRejectedTickets({ commit, dispatch }, { startAt = '' }) {
      try {
        const { docs } = await fb.collection('tickets')
          .where('status', 'in', ['auth', 'rejected'])
          .orderBy('created_at', 'desc')
          .startAfter(startAt)
          .limit(50)
          .get()
        if (docs.length > 0) {
          const items = await Promise.all(docs.map(async item => {
            const data = item.data()
            let products = []
            if (data.status === 'review' || data.status === 'auth') {
              products = await item.ref.collection('productos').get()
              products = await Promise.all(products.docs.map(product => {
                const prodData = product.data()
                return { ...prodData, id: product.id }
              }))
            }
            return {
              ...data,
              products,
              id: item.id
            }
          }))

          commit('addTickets', items)
          dispatch('getRejectedTickets', { startAt: docs[docs.length - 1] })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getMoreTickets({ commit, dispatch }, { startAt = '' }) {
      try {
        const { docs } = await fb.collection('tickets')
          .where('status', 'in', ['pending', 'review'])
          .orderBy('created_at', 'desc')
          .startAfter(startAt)
          .limit(50)
          .get()

        if (docs.length > 0) {
          const items = await Promise.all(docs.map(async item => {
            const data = item.data()
            let products = []
            if (data.status === 'review' || data.status === 'auth') {
              products = await item.ref.collection('productos').get()
              products = await Promise.all(products.docs.map(product => {
                const prodData = product.data()
                return { ...prodData, id: product.id }
              }))
            }
            return {
              ...data,
              products,
              id: item.id
            }
          }))

          commit('addTickets', items)
          dispatch('getMoreTickets', { startAt: docs[docs.length - 1] })
        } else {
          dispatch('getRejectedTickets', { startAt: '' })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async syncTickets({ commit }, { startAt = '' }) {
      try {
        const { docs } = await fb.collection('tickets')
          .where('status', 'in', ['pending', 'review'])
          .orderBy('created_at', 'desc')
          .startAfter(startAt)
          .limit(50)
          .get()

        if (docs.length > 0) {
          const items = await Promise.all(docs.map(async item => {
            const data = item.data()
            let products = []
            if (data.status === 'review' || data.status === 'auth') {
              products = await item.ref.collection('productos').get()
              products = await Promise.all(products.docs.map(product => {
                const prodData = product.data()
                return { ...prodData, id: product.id }
              }))
            }
            return {
              ...data,
              products,
              id: item.id
            }
          }))

          commit('replaceTickets', items)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getTickets({ commit, dispatch }, { startAt = '' }) {
      commit('setLoading', true)
      commit('setTickets', [])
      dispatch('getMoreTickets', { startAt })
      commit('setLoading', false)
    },
    async createIntent({ commit, dispatch }, newTicket) {
      commit('setLoading', true)
      try {
        const { user, id, file, amount, products, inReview, ...more } = newTicket

        if (file) {
          const storageRef = storage.ref()
          const ext = file.name.split('.').pop()
          const spaceRef = storageRef.child(`/tickets/${id}.${ext}`)
          const snapshot = await spaceRef.put(file)
          more.image = await snapshot.ref.getDownloadURL()
        }
        const doc = await fb.collection('tickets').doc(id)
        const status = inReview ? 'review' : 'auth'
        console.log({ ...more, amount, status })
        await doc.update({ ...more, amount, status })
        for (const index in products) {
          await doc.collection('productos').add(products[index])
        }

        await fetch('https://us-central1-hisense-euro-2021.cloudfunctions.net/api/createIntent', {
          body: JSON.stringify({ userId: user.userId, ticket: id, amount }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        dispatch('getTickets', {})
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async updateTicket({ commit, dispatch }, newTicket) {
      commit('setLoading', true)
      try {
        const { user, id, file, amount, products, ...more } = newTicket
        if (file) {
          const storageRef = storage.ref()
          const ext = file.name.split('.').pop()
          const spaceRef = storageRef.child(`/tickets/${id}.${ext}`)
          const snapshot = await spaceRef.put(file)
          more.image = await snapshot.ref.getDownloadURL()
        }
        const doc = await fb.collection('tickets').doc(id)
        console.log({ ...more, amount })
        await doc.update({ ...more, amount })
        for (const index in products) {
          const { id: productId, ...product } = products[index]
          await doc.collection('productos')
            .doc(productId)
            .update(product)
        }
        if (more.status === 'auth') {
          dispatch('reviewTicket', { id, status: 'auth' })
        } else {
          dispatch('getTickets', {})
        }
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async deleteProduct(a, { id, productId }) {
      try {
        await fb.collection('tickets')
          .doc(id)
          .collection('productos')
          .doc(productId)
          .delete()
      } catch (err) {
        console.log(err)
      }
    },
    async rejectTicket({ commit, dispatch }, { id, comment }) {
      commit('setLoading', true)
      try {
        const doc = await fb.collection('tickets').doc(id)
        await doc.update({ status: 'rejected', comment })
        dispatch('getTickets', {})
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async getPoints({ commit }) {
      commit('setLoading', true)
      try {
        const { docs } = await fb.collection('points').get()
        const items = await Promise.all(docs.map(async item => {
          const data = await item.data()
          return {
            ...data,
            id: item.id
          }
        }))
        commit('setPoints', items)
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async savePoints({ commit, dispatch }, points) {
      commit('setLoading', true)
      try {
        await Promise.all(points.map(async ({ id, ...params }) => {
          if (id) {
            return fb.collection('points').doc(id).update(params)
          }

          return fb.collection('points').add(params)
        }))
        dispatch('getPoints')
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async deletePoints({ commit, dispatch }, { id }) {
      commit('setLoading', true)
      try {
        await fb.collection('points').doc(id).delete()
        dispatch('getPoints')
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async updateUser({ commit, dispatch }, { id, ...user }) {
      commit('setLoading', true)
      try {
        const doc = await fb.collection('usuarios').doc(id)
        await doc.update(user)
        dispatch('getTickets', {})
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async updateQuestion({ commit, dispatch }, { id, ...question }) {
      commit('setLoading', true)
      try {
        if (id) {
          await fb.collection('preguntas').doc(id).update(question)
        } else {
          await fb.collection('preguntas').add(question)
        }
        dispatch('getQuestions', {})
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    },
    async reviewTicket({ commit, dispatch }, { id, status, comment = '' }) {
      commit('setLoading', true)
      try {
        if (status === 'rejected') {
          let userSelected
          const { docs } = await fb.collection('intentos')
            .where('ticketId', '==', id)
            .limit(1)
            .get()

          const [intento] = docs
          const intentoData = await intento.data()
          if (!intentoData.used) {
            await intento.ref.delete()
            const users = await fb.collection('usuarios')
              .where('userId', '==', intentoData.user.userId)
              .limit(1)
              .get()
            if (!users.empty) {
              const [user] = users.docs
              const userData = await user.data()
              userSelected = userData
              await user.ref.update({
                vidas: userData.vidas - 1
              })
            }
          } else {
            const users = await fb.collection('usuarios')
              .where('ticketId', '==', intento.id)
              .limit(1)
              .get()

            if (!users.empty) {
              const [user] = users.docs
              userSelected = user.data()
              const otherTickets = await fb.collection('tickets')
                .where('user.userId', '==', userSelected.userId)
                .where('status', '==', 'auth')
                .get()

              let ticketsAuth = otherTickets.empty ? [] : otherTickets.docs.map(ticket => ticket.id)
              ticketsAuth = ticketsAuth.filter(ticket => ticket !== id)

              let dataIntent = {}
              let ticketId = ''
              if (ticketsAuth.length > 0) {
                const intents = await fb.collection('intentos')
                  .where('ticketId', 'in', ticketsAuth)
                  .where('user.userId', '==', userSelected.userId)
                  .orderBy('puntos', 'desc')
                  .limit(1)
                  .get()

                if (!intents.empty) {
                  ticketId = intents.docs[0].id
                  dataIntent = intents.docs[0].data()
                }
              }

              await user.ref.update({
                ticketId,
                puntos: dataIntent.puntos || 0,
                puntosTicket: dataIntent.puntosTicket || 0,
                puntosRespuesta: dataIntent.puntosRespuesta || 0
              })
            }
          }
          if (userSelected) {
            const formData = new FormData()
            formData.append('nombre', userSelected.userName)
            formData.append('codigo', id)
            formData.append('comentario', comment)
            formData.append('email', userSelected.email)
            fetch('https://hisenseenlaeuro.com/envio_mail_error/envio-email.php', {
              method: 'POST',
              body: formData
            }).then(() => console.log('Email sended')).catch((err) => console.log(err))
          }
        }

        const doc = await fb.collection('tickets').doc(id)
        await doc.update({ status, comment })
        dispatch('getTickets', {})
      } catch (err) {
        console.log(err)
      }
      commit('setLoading', false)
    }
  },
  modules: {
  }
})
