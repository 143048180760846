import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        redirect: { name: 'Tickets' }
      },
      {
        path: 'tickets',
        name: 'Tickets',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets.vue')
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
      },
      {
        path: 'points',
        name: 'Points',
        component: () => import(/* webpackChunkName: "points" */ '../views/Points.vue')
      },
      {
        path: 'questions',
        name: 'Questions',
        component: () => import(/* webpackChunkName: "questions" */ '../views/Questions.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
