import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VModal from 'vue-js-modal'
import Tooltip from 'vue-directive-tooltip'
import VueClipboard from 'vue-clipboard2'
import VueDateFns from 'vue-date-fns'
import VueFileAgent from 'vue-file-agent'
import { auth } from './firebase'
import { i18n } from './i18n'
import VueCrontab from 'vue-crontab'
import VueSwal from 'vue-swal'

Vue.config.productionTip = false
Vue.use(VueCrontab)
Vue.use(VueSwal)

// Clipboard
Vue.use(VueClipboard)
// Date formatter
Vue.use(VueDateFns)
// Dropzone
Vue.use(VueFileAgent)
// Modals
Vue.use(VModal, { dialog: true })
// Tooltip
Vue.use(Tooltip)

let app

auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }

  store.commit('setUser', user)
})
