<template>
  <div class="header">
    <div class="header__breadcrumbs">{{ getRouteName() }}</div>
    <div class="header__texts" @click="logout">{{ $t("header.logout") }}</div>
  </div>
</template>

<script>
import { defaultLang } from '../i18n'

export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    getRouteName() {
      let name = 'Tickets'

      if (defaultLang === 'en') {
        name = this.$route.name
      } else {
        if (this.$route.name === 'Users') {
          name = this.$t('users.title')
        } else if (this.$route.name === 'Points') {
          name = this.$t('points.title')
        } else if (this.$route.name === 'Questions') {
          name = this.$t('questions.title')
        }
      }

      return name
    }
  }
}
</script>

<style lang="scss">
@import "~styles/components/header.scss";
</style>
