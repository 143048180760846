<template>
  <div>
    <app-sidebar />
    <div class="main">
      <app-header />
      <div class="main__content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import AppSidebar from '../components/AppSidebar.vue'

export default {
  name: 'Home',
  components: { AppHeader, AppSidebar }
}
</script>
