<template>
  <div class="sidebar">
    <sellia-logo class="sidebar__logo" />
    <li v-for="(item, index) in items" :key="index" class="sidebar__item"
      @click="goToRoute(item.route)"
      :class="{ 'sidebar__item-selected': item.route === $route.name }">
      <div class="sidebar__icon"
        :class="{ 'sidebar__icon-selected': item.route === $route.name }">
        <component :is="item.icon" />
      </div>
      <div>
        <span class="title">{{ item.title }}</span>
      </div>
    </li>
  </div>
</template>

<script>
import UserIcon from '@/assets/icons/account.svg'
import TicketIcon from '@/assets/icons/ticket.svg'
import LabelIcon from '@/assets/icons/label.svg'
import InfoIcon from '@/assets/icons/info.svg'
import SelliaLogo from '@/assets/images/sellia_white.svg'

export default {
  components: { UserIcon, TicketIcon, LabelIcon, InfoIcon, SelliaLogo },
  data() {
    return {
      items: [
        { title: this.$t('tickets.title'), icon: 'ticket-icon', route: 'Tickets' },
        { title: this.$t('users.title'), icon: 'user-icon', route: 'Users' },
        { title: this.$t('points.title'), icon: 'label-icon', route: 'Points' },
        { title: this.$t('questions.title'), icon: 'info-icon', route: 'Questions' }
      ]
    }
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route })
    }
  }
}
</script>

<style lang='scss'>
@import "~styles/components/sidebar.scss";
</style>
