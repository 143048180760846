import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyAYm9noruQg2Lf4YrmG8VJydwqQySLqF9Q',
  authDomain: 'hisense-euro-2021.firebaseapp.com',
  projectId: 'hisense-euro-2021',
  storageBucket: 'hisense-euro-2021.appspot.com',
  messagingSenderId: '788295741639',
  appId: '1:788295741639:web:b1db772e8e850b1b170ee2'
}

firebase.initializeApp(firebaseConfig)

// utils
export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()

// export utils/refs
export default db
